// Generated by Framer (a96673f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useConstant, useIsOnFramerCanvas, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Accordion from "https://framerusercontent.com/modules/blQ2IphFrCpTvuwBxzKT/WP5u1odxPaxsM8wkBCR0/XzF450ugM.js";
import * as localizedValues from "./GSMubpxN7-0.js";
const AccordionFonts = getFonts(Accordion);

const cycleOrder = ["DpR5IIjUX"];

const serializationHash = "framer-Xq6lm"

const variantClassNames = {DpR5IIjUX: "framer-v-1n0cu48"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {fcQKSGRXi: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const isSet = (value) => {
    return value !== undefined && value !== null && value !== "";
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({desc1, desc2, desc3, desc4, height, id, title1, title2, title3, title4, width, ...props}) => { return {...props, dJQ0XtTvC: title4 ?? props.dJQ0XtTvC ?? "Discovery", ELsCp66ye: desc1 ?? props.ELsCp66ye ?? "An audit to understand your brand, your competition, and how to cut through the noise of a crowded industry.", JWAoIEcYT: title2 ?? props.JWAoIEcYT ?? "Discovery", ln0tEgnvG: title3 ?? props.ln0tEgnvG ?? "Discovery", QmG5gISvo: desc3 ?? props.QmG5gISvo ?? "An audit to understand your brand, your competition, and how to cut through the noise of a crowded industry.", t95_2KDCF: desc2 ?? props.t95_2KDCF ?? "An audit to understand your brand, your competition, and how to cut through the noise of a crowded industry.", Y0L3Xmxl5: desc4 ?? props.Y0L3Xmxl5 ?? "An audit to understand your brand, your competition, and how to cut through the noise of a crowded industry.", YTibi1Sym: title1 ?? props.YTibi1Sym ?? "Discovery"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title1?: string;desc1?: string;title2?: string;desc2?: string;title3?: string;desc3?: string;title4?: string;desc4?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, YTibi1Sym, ELsCp66ye, JWAoIEcYT, t95_2KDCF, ln0tEgnvG, QmG5gISvo, dJQ0XtTvC, Y0L3Xmxl5, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "DpR5IIjUX", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isOnCanvas = useIsOnFramerCanvas()

const initialVariant = useConstant(() => variant)

const ref1 = React.useRef<HTMLElement>(null)

const visible = isSet(YTibi1Sym)

const visible1 = isSet(JWAoIEcYT)

const visible2 = isSet(ln0tEgnvG)

const visible3 = isSet(dJQ0XtTvC)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1n0cu48", className, classNames)} data-framer-name={"Variant 1"} initial={isOnCanvas ? variant : initialVariant } layoutDependency={layoutDependency} layoutId={"DpR5IIjUX"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}>{visible && (<motion.div className={"framer-1l3dqwi-container"} layoutDependency={layoutDependency} layoutId={"njpKeO97c-container"}><Accordion BEAmmdY_O={YTibi1Sym} bmXvhsVXO={ELsCp66ye} height={"100%"} id={"njpKeO97c"} layoutId={"njpKeO97c"} style={{width: "100%"}} variant={"Aat7mxiRU"} width={"100%"}/></motion.div>)}{visible1 && (<motion.div className={"framer-8jvcbm-container"} layoutDependency={layoutDependency} layoutId={"xCzb7n3lR-container"}><Accordion BEAmmdY_O={JWAoIEcYT} bmXvhsVXO={t95_2KDCF} height={"100%"} id={"xCzb7n3lR"} layoutId={"xCzb7n3lR"} style={{width: "100%"}} variant={"P01jNjs7T"} width={"100%"}/></motion.div>)}{visible2 && (<motion.div className={"framer-1cv3vy3-container"} layoutDependency={layoutDependency} layoutId={"bVHay_t8p-container"}><Accordion BEAmmdY_O={ln0tEgnvG} bmXvhsVXO={QmG5gISvo} height={"100%"} id={"bVHay_t8p"} layoutId={"bVHay_t8p"} style={{width: "100%"}} variant={"P01jNjs7T"} width={"100%"}/></motion.div>)}{visible3 && (<motion.div className={"framer-181cf4z-container"} layoutDependency={layoutDependency} layoutId={"FjMy7qy91-container"}><Accordion BEAmmdY_O={dJQ0XtTvC} bmXvhsVXO={Y0L3Xmxl5} height={"100%"} id={"FjMy7qy91"} layoutId={"FjMy7qy91"} style={{width: "100%"}} variant={"P01jNjs7T"} width={"100%"}/></motion.div>)}</motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-Xq6lm[data-border=\"true\"]::after, .framer-Xq6lm [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Xq6lm.framer-1fns1vr, .framer-Xq6lm .framer-1fns1vr { display: block; }", ".framer-Xq6lm.framer-1n0cu48 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-Xq6lm .framer-1l3dqwi-container, .framer-Xq6lm .framer-8jvcbm-container, .framer-Xq6lm .framer-1cv3vy3-container, .framer-Xq6lm .framer-181cf4z-container { flex: none; height: auto; position: relative; width: 550px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Xq6lm.framer-1n0cu48 { gap: 0px; } .framer-Xq6lm.framer-1n0cu48 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-Xq6lm.framer-1n0cu48 > :first-child { margin-top: 0px; } .framer-Xq6lm.framer-1n0cu48 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 384
 * @framerIntrinsicWidth 550
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"YTibi1Sym":"title1","ELsCp66ye":"desc1","JWAoIEcYT":"title2","t95_2KDCF":"desc2","ln0tEgnvG":"title3","QmG5gISvo":"desc3","dJQ0XtTvC":"title4","Y0L3Xmxl5":"desc4"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerGSMubpxN7: React.ComponentType<Props> = withCSS(Component, css, "framer-Xq6lm") as typeof Component;
export default FramerGSMubpxN7;

FramerGSMubpxN7.displayName = "Accordion Block";

FramerGSMubpxN7.defaultProps = {height: 384, width: 550};

addPropertyControls(FramerGSMubpxN7, {YTibi1Sym: {defaultValue: "Discovery", displayTextArea: false, title: "Title 1", type: ControlType.String}, ELsCp66ye: {defaultValue: "An audit to understand your brand, your competition, and how to cut through the noise of a crowded industry.", displayTextArea: false, title: "Desc 1", type: ControlType.String}, JWAoIEcYT: {defaultValue: "Discovery", displayTextArea: false, title: "Title 2", type: ControlType.String}, t95_2KDCF: {defaultValue: "An audit to understand your brand, your competition, and how to cut through the noise of a crowded industry.", displayTextArea: false, title: "Desc 2", type: ControlType.String}, ln0tEgnvG: {defaultValue: "Discovery", displayTextArea: false, title: "Title 3", type: ControlType.String}, QmG5gISvo: {defaultValue: "An audit to understand your brand, your competition, and how to cut through the noise of a crowded industry.", displayTextArea: false, title: "Desc 3", type: ControlType.String}, dJQ0XtTvC: {defaultValue: "Discovery", displayTextArea: false, title: "Title 4", type: ControlType.String}, Y0L3Xmxl5: {defaultValue: "An audit to understand your brand, your competition, and how to cut through the noise of a crowded industry.", displayTextArea: false, title: "Desc 4", type: ControlType.String}} as any)

addFonts(FramerGSMubpxN7, [...AccordionFonts])